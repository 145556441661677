import React, { FC, useEffect } from "react"
import { createPortal } from "react-dom"
import { useStore } from "zustand"
import { useShallow } from "zustand/react/shallow"
import { usePageContainerRef } from "../pageContainerRef"
import { useCalculationPrice } from "./useCalculationPrice"
import { calculationContactStore } from "../../stores/calculationContactStore"
import { calculationStore } from "../../stores/calculationStore"
import { ClientConfig } from "../../services/clientconfig"
import { useCalculationPriceRequest } from "./useCalculationPriceRequest"
import { Modal } from "../modal"
import { createApiPayload } from "./leafletsService"
import { formatPrice } from "../../services/formatPrice"

type Props = {
  onSent: (demandId: string) => void
  onScrollTo: (section: "customQuantity" | "contacts") => void
}

export const CalculationPrice: FC<Props> = ({ onSent, onScrollTo }) => {
  const [showError, setShowError] = React.useState(false)
  const { contactData, validateContacts } = useStore(
    calculationContactStore,
    useShallow(s => ({
      contactData: s.state,
      validateContacts: s.validate,
    }))
  )
  const { formData, files, validate } = useStore(
    calculationStore,
    useShallow(s => ({
      formData: s.state,
      files: s.files,
      validate: s.validate,
    }))
  )
  const price = useCalculationPrice()
  const pageContainerRef = usePageContainerRef()
  const {
    isProcessing,
    success: successfullySent,
    errorMessage: sentError,
    demandId,
    upload,
  } = useCalculationPriceRequest()

  useEffect(() => {
    if (successfullySent) {
      onSent(demandId)
    }
  }, [successfullySent])

  useEffect(() => {
    if (sentError) {
      setShowError(true)
    }
  }, [sentError])

  const handleCloseError = () => {
    setShowError(false)
  }

  const handleSend = () => {
    if (!validate()) {
      onScrollTo("customQuantity")
      return
    }

    if (!validateContacts()) {
      onScrollTo("contacts")
      return
    }

    upload(`${ClientConfig.apiUrl}/api/calc/leaflets-demand`, files, {
      ...createApiPayload(formData),
      ...contactData,
    })
  }

  if (!pageContainerRef) {
    return null
  }

  return (
    <div className="calculation-price">
      <div className="block">
        <div className="block-item">
          <div className="price-container">
            <div className="price-table">
              <div className="price-col">
                <span>Za kus:</span>
                <span>Celkem:</span>
              </div>
              <div className="price-col">
                {price.isLoading ? (
                  <LoadingPanel />
                ) : (
                  <span>{formatPrice(price.priceOne)}&nbsp;Kč + DPH</span>
                )}
                {price.isLoading ? (
                  <LoadingPanel />
                ) : (
                  <span>{formatPrice(price.totalPrice)}&nbsp;Kč + DPH</span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="block-item">
          <button
            className="btn btn--secondary btn-send"
            disabled={isProcessing || price.isLoading}
            onClick={handleSend}
          >
            {isProcessing ? (
              <img src="/spinning-circles.svg" className="loading"></img>
            ) : (
              "Odeslat objednávku"
            )}
          </button>
        </div>
      </div>
      {createPortal(
        <Modal
          id="calculation-sent-error"
          show={showError}
          onClose={handleCloseError}
        >
          <h2>Chyba při odesílání poptávky</h2>
          <p>Došlo k chybě při odesílání poptávky. Zkuste to prosím znovu.</p>
          <button className="btn btn--secondary" onClick={handleCloseError}>
            Zavřít
          </button>
        </Modal>,
        pageContainerRef
      )}
    </div>
  )
}

const LoadingPanel: FC = () => {
  return (
    <div className="price-loading">
      <div className="price-loading-text">&nbsp;</div>
    </div>
  )
}
